import {createContext, useReducer} from 'react'
import { AuthReducer } from '../reducer/AuthReducer';

const Auth = createContext()

const INITIAL_STATE = {
    value: true,
  };

export function LoggedInProvider({children}){

    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

    return <Auth.Provider value={[state, dispatch]}>
        {children}
    </Auth.Provider>
}

export default Auth