import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./components/Layout/Layout";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Services from "./components/Pages/Services";
import Track from "./components/Pages/Track";
import Contact from "./components/Pages/Contact";
import { LoggedInProvider } from "./services/context/store";

function App() {
  return (
    <LoggedInProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout children={<Home />} />} />
          <Route path="/about" element={<Layout children={<About />} />} />
          <Route
            path="/services"
            element={<Layout children={<Services />} />}
          />
          <Route path="/track" element={<Layout children={<Track />} />} />
          <Route
            path="/contact-us"
            element={<Layout children={<Contact />} />}
          />
        </Routes>
      </BrowserRouter>
    </LoggedInProvider>
  );
}

export default App;
