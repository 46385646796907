import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import Loadingicon from "../Loading";
import Table from "react-bootstrap/Table";
import Barcode from "../../assets/Images/barcode.png";
import SmallScreenHistory from "../SmallScreenHistory";

const Track = () => {
  const [loading, setLoading] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [result, setResult] = useState([]);
  const [clicked, setClicked] = useState(false)
  const [shipmentInfo, setShipmentInfo] = useState();

  const handleChange = (e) => setTrackingNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await fetch(
      `${
        process.env.REACT_APP_API_URL
      }/track/${trackingNumber.toLocaleUpperCase()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setResult(data.foundData);
    setShipmentInfo(data.foundShipment);
    setClicked(true)
    setLoading(false);
  };

  return (
    <div className="container p-3 mb-[134px]">
      <title>Track your shipment</title>
      <div className="bg-white p-3">
        <div className="mb-9">
          <h6 className="text-3xl">Track</h6>
        </div>
        <div className="border p-6">
          <form onSubmit={handleSubmit}>
            <div className="text-center font-bold text-2xl mb-7">
              Enter Tracking Number
            </div>
            <div className="flex justify-center">
              <TextField
                id="outlined-basic"
                label="Enter Tracking Number"
                variant="outlined"
                className="md:w-3/5 w-full"
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex justify-center mt-7">
              <Button
                type="submit"
                className="text-center"
                variant="outlined"
                size="large"
              >
                Track
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="p-3 mt-5">
        {loading ? (
          <Loadingicon />
        ) : shipmentInfo ? (
          <>
            <div className="mb-16">
              <div className="text-center mb-6 text-3xl font-bold">
                <div className="flex justify-center mb-3">
                  <img src={Barcode} alt="Barcode" />
                </div>
                {shipmentInfo.trackingNumber}
              </div>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="border-b">
                    <h4>Sender Information</h4>
                  </div>
                  <div className="mt-4 text-muted">
                    <p className="m-0">{shipmentInfo.SenderInfo.fullName}</p>
                    <p className="m-0">{shipmentInfo.SenderInfo.number}</p>
                    <p className="m-0">{shipmentInfo.SenderInfo.email}</p>
                    <p className="m-0">{shipmentInfo.SenderInfo.country}</p>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="border-b">
                    <h4>Receiver Information</h4>
                  </div>
                  <div className="mt-4 text-muted">
                    <p className="m-0">{shipmentInfo.RecieverInfo.fullName}</p>
                    <p className="m-0">{shipmentInfo.RecieverInfo.number}</p>
                    <p className="m-0">{shipmentInfo.RecieverInfo.email}</p>
                    <p className="m-0">{shipmentInfo.RecieverInfo.country}</p>
                  </div>
                </div>
              </div>
              <div className="bg-stone-300 text-white uppercase text-center p-3 font-bold mt-6">
                Shipment status: {shipmentInfo.shipmentStatus}
              </div>
              <div className="col-12">
                <div className="border-b mt-8">
                  <h4>Shipment Information</h4>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Origin:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.origin}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Destination:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.destination}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Shipment Mode:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.shipmentmode}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Comments:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.comments}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Pick-up Date:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.pickupDate.slice(0, 10)}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Pick-up Time:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.pickupTime}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Depature Date:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.DepatureDate.slice(0, 10)}
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Expected Delivery Date:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.ExpectedDeliveryDate.slice(
                          0,
                          10
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Weight:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.weight}kg
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Height:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.height}cm
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Width:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.width}cm
                      </p>
                    </div>
                    <div className="mt-6">
                      <h6 className="text-lg m-0">Length:</h6>
                      <p className="m-0 text-base text-muted">
                        {shipmentInfo.ShipmentInfo.length}cm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b my-8">
              <h4>Shipment History</h4>
            </div>
            <div className="hidden md:block">
              <Table bordered hover>
                <thead>
                  <tr className="bg-blue-500">
                    <th>Date</th>
                    <th>Time </th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((data) => {
                    return (
                      <tr>
                        <td>{data.date}</td>
                        <td>{data.time}</td>
                        <td>{data.location}</td>
                        <td>{data.status}</td>
                        <td>{data.remark}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <SmallScreenHistory data={result} />
          </>
        ) : (
          <div>{clicked && "No result found"}</div>
        )}
      </div>
    </div>
  );
};

export default Track;
