import React from "react";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaLuggageCart,
  FaMoneyBill,
  FaPlaneDeparture,
  FaShippingFast,
  FaTruck,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { ACTION_TYPES } from "../../services/actions/actions";
import Auth from "../../services/context/store";

const Services = () => {
  const [, dispatch] = React.useContext(Auth);
  return (
    <div>
      <title>Services</title>
      <div className="jumbotron text-center servicesImage">
        <div className="servicestitle lg:absolute relative">
          <h1 className="md:text-5xl text-3xl font-bold font-poppins textAnimation">
            OUR SERVICES
          </h1>
          <hr className="my-4" />
          <p className="text-sm font-normal">
            connecting people with goods, services and ideas creates
            opportunities and improves lives. at Mjsecurity we believe that a
            connected world is a better world, and that belief guides everything
            we do.​
          </p>
          <p className="lead">
            <Link
              className="btn btn-warning text-white font-bold"
              to="/track"
              role="button"
              onClick={() => dispatch({ type: ACTION_TYPES.START })}
            >
              Track Your Package
            </Link>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="text-center mt-20">
          <div className="flex justify-center items-center">
            <div className="border-yellow-300 border-b-[4px] w-[10%]"></div>
            <div className="mx-3 italic text-muted font-semibold">
              What We Do - Our Delivery Services
            </div>
            <div className="border-yellow-300 border-b-[4px] w-[10%]"></div>
          </div>
          <div className="mt-16">
            <motion.div
              className="row"
              whileInView={{ opacity: 1, transform: "translateY(0px)" }}
              initial={{ opacity: 0, transform: "translateY(250px)" }}
              transition={{ delay: 0.6 }}
            >
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaClock color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">Express</div>
              </div>
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaTruck color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">Ground</div>
              </div>
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaPlaneDeparture color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">
                  International
                </div>
              </div>
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaLuggageCart color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">Freight</div>
              </div>
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaMoneyBill color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">Flat rate</div>
              </div>
              <div className="col-md-2 mb-8">
                <div className="flex justify-center mb-3">
                  <FaShippingFast color="blue" size={50} />
                </div>
                <div className="text-yellow-500 font-extrabold">
                  Same-Day Delivery
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
