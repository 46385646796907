import Card from "react-bootstrap/Card";
import { motion } from "framer-motion";

function Testimony({ text, Image, csat, name }) {
  return (
    <motion.div
      whileInView={{ opacity: 1, transform: "translateX(0)" }}
      initial={{ opacity: 0, transform: "translateX(-250px)" }}
      transition={{ delay: 1 }}
    >
      <Card style={{ width: "100%", minHeight: 300 }}>
        <Card.Body>
          <Card.Title>{text}</Card.Title>
          <div className="flex mt-4">
            <img src={Image} alt="" className="rounded-full w-10 h-10 mr-5 object-cover" />
            <div>
              <p className="m-0">{name}</p>
              <p className="text-muted m-0">{csat}</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default Testimony;
