import React from "react";
import FullScreenDialog from "../FullScreenDialog";
import Image0 from "../../assets/Images/image0.jpg";
import Image1 from "../../assets/Images/image1.jpg";
import Image2 from "../../assets/Images/image2.jpg";
import Image3 from "../../assets/Images/image3.jpg";
import Image4 from "../../assets/Images/image4.jpg";
import { FaMailBulk, FaAddressCard, FaPhoneSquare } from "react-icons/fa";
import Image5 from "../../assets/Images/image5.jpg";

const Footer = () => {
  return (
    <div className="bg-blue-800 text-white text-left p-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <h6 className="uppercase font-semibold">Mjsecurity</h6>
            <div className="row">
              {[Image0, Image1, Image2, Image3, Image4, Image5].map(
                (item, index) => {
                  return (
                    <div className="col-lg-4 mb-3" key={index}>
                      <FullScreenDialog image={item} />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <h6 className="uppercase font-semibold">Contact Info</h6>
            {/* <div className="mb-2">Phone: +2348060506980</div> */}
            <div className="mb-2 flex items-center">
              <FaMailBulk /> <span className="ml-3"> support@mjsecurity.cc</span>
            </div>
            <div className="mb-2 flex items-center">
              <FaPhoneSquare />  <span className="ml-3"> (301) 458-0587</span>
            </div>
            <div className="mb-2 flex items-center">
              <FaAddressCard />  <span className="ml-3"> 10406 MAIN ST, FAIRFAX, VA 22030, United States</span>
            </div>
            {/* <div className="mb-2">
              Store open: 9:00 – 17:30, Monday – Friday
            </div>
            <div>10:00 – 17:00, Saturday - Sunday</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
