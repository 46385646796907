import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Auth from "../../services/context/store";
import { ACTION_TYPES } from "../../services/actions/actions";

function Navigation() {
  const [, dispatch] = React.useContext(Auth);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className={`bg-blue-800 sticky-top shadow-sm`}>
      <div className="container">
        <Navbar
          expand="md"
          style={{ padding: "10px 0px", marginRight: "125px" }}
        >
          <>
            <div
              id="nav-icon1"
              onClick={handleToggle}
              className={open ? "open" : ""}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Navbar.Brand style={{ marginRight: "auto" }}>
              <Link
                to="/"
                className="nav-link font-semibold text-3xl text-white md:ml-0 ml-8"
              >
                Mjsecurity
              </Link>
            </Navbar.Brand>

            <Navbar.Offcanvas
              style={{ width: "100%" }}
              id="offcanvasNavbar-expand-md"
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="start"
              show={open}
              onHide={() => setOpen(false)}
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  onClick={() => setOpen(false)}
                  className="justify-content-end flex-grow-1 pe-3 items-baseline"
                >
                  <div className="d-flex navbar-nav">
                    <Link
                      to="/"
                      className="nav-link mr-1 px-2"
                      onClick={() => dispatch({ type: ACTION_TYPES.START })}
                    >
                      <span className="flex items-center md:text-white text-gray-950">
                        <span className="ml-2"> Home</span>
                      </span>
                    </Link>
                    <Link
                      to="/about"
                      className="nav-link mr-1 px-2"
                      onClick={() => dispatch({ type: ACTION_TYPES.START })}
                    >
                      <span className="flex items-center md:text-white text-gray-950">
                        <span className="ml-2"> About</span>
                      </span>
                    </Link>
                    <Link
                      to="/services"
                      className="nav-link mr-1 px-2"
                      onClick={() => dispatch({ type: ACTION_TYPES.START })}
                    >
                      <span className="flex items-center md:text-white text-gray-950">
                        <span className="ml-2"> Services</span>
                      </span>
                    </Link>
                    <Link
                      to="/contact-us"
                      className="nav-link mr-1 px-2"
                      onClick={() => dispatch({ type: ACTION_TYPES.START })}
                    >
                      <span className="flex items-center md:text-white text-gray-950">
                        <span className="ml-2">Contact</span>
                      </span>
                    </Link>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </>
        </Navbar>
      </div>
    </div>
  );
}

export default Navigation;
