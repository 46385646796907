import ProgressBar from "@ramonak/react-progress-bar";

const ProgressBars = ({ end, color, text }) => {
  return (
    <div>
      <h6>{text}</h6>
      <ProgressBar completed={end} bgColor={color} labelColor="white" animateOnRender={true}/>
    </div>
  );
};

export default ProgressBars;
