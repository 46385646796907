import { ACTION_TYPES } from "../actions/actions";

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.START:
      return {
        value: true,
      };
    case ACTION_TYPES.STOP:
      return {
        value: false,
      };

    default:
      return state;
  }
};
