import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProgressBars from "../ProgressBars";
import Testimony from "../Testimony";
import Image from "../../assets/Images/model1.jpg";
import Image1 from "../../assets/Images/model2.jpg";
import Image2 from "../../assets/Images/model3.jpg";
import Video from "../../assets/Videos/Shipping.mp4";
import Animate from "../Animate";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Slider from "../Slider";
import CounterFile from "../Counter";
import Auth from "../../services/context/store";
import { ACTION_TYPES } from "../../services/actions/actions";

const Home = () => {
  const { ref, inView } = useInView();
  const [bars, setBars] = useState({
    1: 0,
    2: 0,
    3: 0,
  });

  const [counterValue, setCounter] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });

  const { ref: ref1, inView: view } = useInView();
  const [, dispatch] = React.useContext(Auth);

  useEffect(() => {
    if (inView) {
      setBars(() => {
        return {
          1: 100,
          2: 85,
          3: 35,
        };
      });
    }
  }, [inView]);

  useEffect(() => {
    if (view) {
      setCounter(() => {
        return {
          1: 15,
          2: 28,
          3: 286,
          4: 20,
        };
      });
    }
  }, [view]);

  return (
    <div>
      <title>Home</title>
      <div className="relative">
        <Slider />
        <div className="title absolute lg:bottom-[136px] md:w-[800px] w-[300px] text-center">
          <h1 className="md:text-5xl text-3xl font-bold font-poppins textAnimation">
            Ship-Manage-Track-Deliver
          </h1>
          <hr className="my-4" />
          <div className="text-center">
            <p className="lead">
              <Link
                className="btn btn-info text-white font-bold"
                to="/track"
                role="button"
                onClick={() => dispatch({ type: ACTION_TYPES.START })}
              >
                Track Your Package
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="jumbotron text-center jumboImage"> */}

      {/* </div> */}
      <div className="container">
        <Animate>
          <div className="bg-stone-100 md:p-8 mt-20 rounded-md">
            <div className="row textAnimation">
              <div className="col-md-4 text-center p-3 mb-4">
                <div className="mb-2">
                  <h5 className="upppercase">Call Center</h5>
                  <div className="border-yellow-300 border-b-[6px] w-[75%]"></div>
                </div>
                <div className="bg-blue-900 text-white mt-2">
                  <p className="m-0">Give us a free call</p>
                  <p className="m-0">(301) 458-0587</p>
                </div>
              </div>
              <div className="col-md-4 text-center p-3 mb-4">
                <div className="mb-2">
                  <h5 className="upppercase">Working Hours</h5>
                  <div className="border-yellow-300 border-b-[6px] w-[75%]"></div>
                </div>
                <div className="bg-blue-900 text-white mt-2">
                  <p className="m-0">Mon-Sat 7AM-5PM</p>
                  <p className="m-0">Sun 9AM-3PM</p>
                </div>
              </div>
              <div className="col-md-4 text-center p-3 mb-4">
                <div className="mb-2">
                  <h5 className="upppercase">Our Location</h5>
                  <div className="border-yellow-300 border-b-[6px] w-[75%]"></div>
                </div>
                <div className="bg-blue-900 text-white mt-2">
                  <p className="m-0">10406 MAIN ST, FAIRFAX,</p>
                  <p className="m-0">VA 22030, United States.</p>
                </div>
              </div>
            </div>
          </div>
        </Animate>
        <Animate>
          <div className={`mt-20 shippingImage md:p-8`}>
            <div className="row">
              <div className="col-md-6 mb-4">
                <motion.div
                  className="relative md:mt-[100px]"
                  whileInView={{
                    opacity: 1,
                    transform: "translateX(0) rotate(0deg)",
                  }}
                  initial={{
                    opacity: 0,
                    transform: "translateX(-250px) rotate(-200deg)",
                  }}
                  transition={{ delay: 0.8, duration: 2 }}
                >
                  <video loop src={Video} autoPlay={true} muted></video>
                </motion.div>
              </div>
              <div className="col-md-6 my-auto">
                <div className="relative">
                  <motion.div
                    whileInView={{ opacity: 1, transform: "translateX(0)" }}
                    initial={{ opacity: 0, transform: "translateX(-250px)" }}
                    transition={{ delay: 0.8 }}
                    className={`text-left md:mt-[130px] p-2`}
                  >
                    <div className="mb-[30px]">
                      <h5 className="font-bold text-4xl text-white">
                        Delivery Timelines
                      </h5>
                      <div className="border-yellow-300 border-b-[9px] w-28 mb-3"></div>
                    </div>
                    <div className="border-gray-900 font-medium bg-slate-400 border-2 p-3 text-white">
                      <p className="m-0">
                        {" "}
                        Same Day Express – Local within 4 hours.
                      </p>
                      <p className="m-0">
                        Same Day Priority – Local within 2 hours.
                      </p>
                      <p className="m-0">
                        Stat/Rush – Urgent/ Direct Delivery. Next-Day Delivery.
                      </p>
                      <p className="m-0">Direct Long Distance.</p>
                      <p className="m-0">Routed Delivery Service.</p>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </Animate>

        <Animate>
          <div className="mt-20 bg-stone-100 rounded-md md:!p-8" ref={ref}>
            <div className="p-2">
              <div className="mb-4">
                <ProgressBars
                  text={"INCREASE IN NUMBER OF LOGISTICS CENTERS"}
                  color="orange"
                  end={bars[1]}
                />
              </div>
              <div className="mb-4">
                <ProgressBars
                  text={"MARKET SHARE IN CE REGION"}
                  color="blue"
                  end={bars[2]}
                />
              </div>
              <div className="mb-4">
                <ProgressBars
                  text={"REVENUE GROWTH PER YEAR"}
                  color="green"
                  end={bars[3]}
                />
              </div>
            </div>
          </div>
        </Animate>
        <Animate>
          <div className="mt-16">
            <h2>What our customers have to say</h2>
          </div>
          <div className="border-yellow-300 border-b-[9px] w-28 mb-3"></div>
          <div className="bg-stone-100 rounded-md md:!p-8">
            <div className="row p-2">
              <div className="col-lg-4 mb-4">
                <Testimony
                  text="Would definitely recommend. Helpful friendly staff who keep you
          updated on the progress of your package. The method of operation is
          really easy and saves so much time."
                  name={"Dave Chapell"}
                  Image={Image}
                  csat={"Excellent service"}
                />
              </div>
              <div className="col-lg-4 mb-4">
                <Testimony
                  text="Chris, my initial assigned agent was informative and helpful. Kay leigh however, took customer service to a whole new level. She was amazing! Managed my expectations all the way through the process. She is a credit to you."
                  name={"Chelsey Lott"}
                  Image={Image1}
                  csat={"Satisfied customer"}
                />
              </div>
              <div className="col-lg-4 mb-4">
                <Testimony
                  text="The team at Mjsecurity were friendly and helpful from the moment we first spoke. They helped me find the right documents for our needs without pressure or excess charges. I was kept informed at every stage regarding what was needed."
                  name={"Vicki Colson"}
                  Image={Image2}
                  csat={"Highly recommend"}
                />
              </div>
            </div>
          </div>
        </Animate>
        <div className="row !my-16" ref={ref1}>
          <div className="col-md-3 text-center mb-3">
            <CounterFile start={0} end={counterValue[1]} child={""} />
            <div className="uppercase text-muted text-lg">Years in service</div>
          </div>
          <div className="col-md-3 text-center mb-3">
            <CounterFile start={0} end={counterValue[2]} child={"K"} />
            <div className="uppercase text-muted text-lg">Km per year</div>
          </div>
          <div className="col-md-3 text-center mb-3">
            <CounterFile start={0} end={counterValue[3]} child={""} />
            <div className="uppercase text-muted text-lg">Location</div>
          </div>
          <div className="col-md-3 text-center mb-3">
            <CounterFile start={0} end={counterValue[4]} child={"K"} />
            <div className="uppercase text-muted text-lg">
              Satisfied Clients
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
