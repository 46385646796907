import React from "react";
import Counter from "react-number-increment";

const CounterFile = ({ start, end, child }) => {
  return (
    <Counter
      start={start} // starting
      end={end} // ends
      speed={0} // 100ms
      style={{ fontSize: "40px", fontWeight: 500 }} // add custom style
      threshold={1} // viewport 1=100%,
      child={child} // add childs ie + , - , *
      offset={5} // increments by 5
      seperate="," // seperates number in comma
    />
  );
};

export default CounterFile;
