import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ image }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img
        src={image}
        className="w-52 h-52 cursor-pointer object-cover"
        alt="gallery"
        onClick={handleClickOpen}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <CloseIcon style={{fontSize:"3rem"}} className="cursor-pointer absolute" onClick={handleClose} />

        <div className="w-full my-auto">
          <img src={image} className="w-full" alt="gallery" />
        </div>
      </Dialog>
    </div>
  );
}
