import Carousel from "react-bootstrap/Carousel";
import Image0 from "../assets/Images/bg.jpg";
import Image1 from "../assets/Images/image8.jpg";
import Image2 from "../assets/Images/image9.jpg";
// import Image3 from "../../assets/Images/image3.jpg";
// import Image4 from "../../assets/Images/image4.jpg";
// import Image5 from "../../assets/Images/image5.jpg";

function Slider() {
  return (
    <Carousel controls={false}>
      <Carousel.Item>
        <img src={Image0} className="w-full h-[650px] object-cover" alt="" text="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Image1} className="w-full h-[650px] object-cover" alt="" text="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Image2} className="w-full h-[650px] object-cover" alt="" text="First slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;
