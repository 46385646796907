import React from "react";
import ContactForm from "../ContactForm";
import Animate from "../Animate";
import ContactImage from "../../assets/Images/contact.jpg";
import { FaAddressCard, FaMailBulk, FaPhoneSquare } from "react-icons/fa";

const Contact = () => {
  return (
    <div>
      <title>Contact Us</title>
      <Animate>
        <div className="row">
          <div className="col-12">
            <div className="contactImage">
              <img src={ContactImage} alt="" />
            </div>
          </div>
        </div>

        <div className="container mt-10">
          <div className="row">
            <div className="col-md-6">
              <h3 className="uppercase">Customer Support</h3>
              <div className="border-yellow-500 border-b-4 w-24"></div>
              <div className="my-6 font-normal">
                Send a quote, complains and questions. We are active 24/7
              </div>
              <ContactForm />
            </div>
            <div className="col-md-6">
              <div className="col-lg-6 mb-4">
                <h6 className="uppercase font-semibold">Contact Info</h6>
                {/* <div className="mb-2">Phone: +2348060506980</div> */}
                <div className="mb-2 flex items-center">
                  <FaMailBulk />{" "}
                  <span className="ml-3"> support@mjsecurity.cc</span>
                </div>
                <div className="mb-2 flex items-center">
                  <FaPhoneSquare />{" "}
                  <span className="ml-3"> (301) 458-0587</span>
                </div>
                <div className="mb-2 flex items-center">
                  <FaAddressCard />{" "}
                  <span className="ml-3">
                    {" "}
                    10406 MAIN ST, FAIRFAX, VA 22030, United States
                  </span>
                </div>
                <div className="my-7">
                  <h6 className="uppercase font-semibold">Opening Hours</h6>

                  <div className="mb-2">Weekdays: 9:00 AM – 5:00 PM</div>
                  <div>Saturday: 9:00 AM – 1:00 PM</div>
                  <div>Sunday: 12 PM - 5:00 PM</div>
                </div>
                <div className="w-full">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3107.4003114987936!2d-77.30811232386291!3d38.84619277173502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64e924c087b6f%3A0x306a17f5334500f4!2s10406%20Main%20St%2C%20Fairfax%2C%20VA%2022030%2C%20USA!5e0!3m2!1sen!2sng!4v1697217856210!5m2!1sen!2sng"
                    width={"360px"}
                    height={"400px"}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="Map location"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 animate-pulse text-center mt-10">
            <h1 className="text-6xl font-bold">24/7</h1>
            <p>We are avaliable to help</p>
          </div>
        </div>
      </Animate>
    </div>
  );
};

export default Contact;
