import React from "react";
import { Link } from "react-router-dom";
import Animate from "../Animate";
import { motion } from "framer-motion";
import Auth from "../../services/context/store";
import { ACTION_TYPES } from "../../services/actions/actions";

const About = () => {
    const [, dispatch] = React.useContext(Auth);
  return (
    <div>
      <title>About Us</title>
      <div className="jumbotron text-center aboutImage">
        <div className="abouttitle lg:absolute relative">
          <h1 className="md:text-5xl text-3xl  font-bold font-poppins textAnimation">
            | Your Delivery Experts
          </h1>
          <p className="italic animate-pulse">
            Safe, Reliable & Transport Solutions
          </p>
          <hr className="my-4" />
          <div className="flex justify-center">
            <Link
              className="btn btn-info text-white font-bold"
              to="/track"
              role="button"
              onClick={() => dispatch({ type: ACTION_TYPES.START })}
            >
              Track Your Package
            </Link>
            <Link
              className="btn btn-warning ml-3 text-white font-bold"
              to="/contact-us"
              role="button"
              onClick={() => dispatch({ type: ACTION_TYPES.START })}
            >
              Send us a quote
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-20">
          <Animate>
            <div className="mb-7">
              <h5 className="text-3xl font-bold">Why Choose us</h5>
              <div className="border-yellow-300 border-b-[9px] w-16"></div>
            </div>
            <motion.div
              whileInView={{ opacity: 1, transform: "translateX(0)" }}
              initial={{ opacity: 0, transform: "translateY(100%)" }}
              transition={{ delay: 0.8 }}
              className="md:w-[400px]"
            >
              <p className="text-muted">
                Our goal is to provide you with the ultimate shipping
                experience. We’re passionate about your satisfactions,
                constantly innovating and timeliness.​
              </p>
            </motion.div>
          </Animate>

          <div className="mt-20 bg-slate-200 p-4">
            <motion.div
              className="row"
              whileInView={{ opacity: 1, transform: "scale(1)" }}
              initial={{ opacity: 0, transform: "scale(0.5)" }}
              transition={{ delay: 1 }}
            >
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3">
                  You're in our heart
                </div>
                <p className="text-muted">
                  Our goal is to provide you with the ultimate shipping
                  experience. We're passionate about your satisfactions,
                  constantly innovating and timeliness.​​
                </p>
              </div>
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3">
                  Consistency and Reliability.
                </div>
                <p className="text-muted">
                  Mjsecurity is committed to delivering on-time service and
                  transit times among the best in the industry. Damage-free
                  deliveries help reduce your hassle and expense from replacing
                  shipped items.
                </p>
              </div>
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3">
                  Technology Makes it More Efficient
                </div>
                <p className="text-muted">
                  Get the immediate visibility you need to track shipments,
                  billing and related documents efficiently and accurately with
                  advanced online and mobile technology.
                </p>
              </div>
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3">
                  A decade of experience
                </div>
                <p className="text-muted">
                  Since launching in 2008, we've become one of the world's
                  Leading cargo Experts. Among the pioneers of the industry and
                  with 15 years' experience, you can be confident that when
                  you're dealing with us, you're dealing with experts.
                </p>
              </div>
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3">
                  {" "}
                  Dedicated support, Felixible hours.
                </div>
                <p className="text-muted">
                  Get access to support around the clock whenever we are open,
                  from our dedicated Client Services team. If you happen to
                  experience any technical issues or form of difficulty on your
                  delivery & tracking process, you can easily get in touch with
                  a member of our knowledgeable team.
                </p>
              </div>
              <div className="col-md-4 mb-16">
                <div className="text-xl font-bold mb-3"> Risk Management</div>
                <p className="text-muted">
                  We've developed an extensive range of risk-management features
                  to help manage all form of risk effectively, helping to secure
                  clients personal data and minimize any form of losses in
                  process of delivery packages.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
