import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingScreen = () => {
  return (
    <div className="h-screen bg-gray-900 text-center flex items-center justify-center">
      <div>
        <Spinner animation="grow" variant="primary" />
        <div className="text-white text-4xl animate-pulse uppercase mt-4">MjSecurity</div>
      </div>
    </div>
  );
};

export default LoadingScreen;
