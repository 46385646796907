import React from "react";

const SmallScreenHistory = ({ data }) => {
  return (
    <div className="border md:hidden block">
      {data.map((result, index) => {
        return (
          <div
            className={`${index % 2 === 0 ? "bg-white" : "bg-stone-500 text-white"} p-3`}
          >
            <p>Date: {result.date}</p>
            <p>Time: {result.time}</p>
            <p>Location: {result.location}</p>
            <p>Status: {result.status}</p>
            <p>Remark: {result.remark}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SmallScreenHistory;
