import React, { useEffect } from "react";
import Footer from "./Footer";
import Navigation from "./Navbar";
import Auth from "../../services/context/store";
import LoadingScreen from "../LoadingScreen";
import { ACTION_TYPES } from "../../services/actions/actions";

function Layout({ children }) {
  const [state, dispatch] = React.useContext(Auth);

  useEffect(() => {
    if (state.value) {
      const timeout = setTimeout(() => {
        dispatch({ type: ACTION_TYPES.STOP });
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dispatch, state.value]);

  return state.value ? (
    <LoadingScreen />
  ) : (
    <>
      <Navigation />
      <div className="mb-8">{children}</div>
      <Footer />
    </>
  );
}

export default Layout;
